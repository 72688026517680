<template>
    <div style="background-color: #e7edf5; color: black">parameter to map:&nbsp;

        <b-form-select style="border:1px solid gray; width: auto; color: red"
                       v-model="parameterToMap" :options="parameterOptions"></b-form-select>

    </div>
</template>

<script>
    export default {
        props: ['namespace'],
        computed: {
            parameterToMap: {
                get() {
                    return this.$store.state[this.namespace].chartParameters.parameterToMap;
                },
                set(parameterToMap) {
                    this.$store.commit(this.namespace + '/chartParameters/setParameterToMap', parameterToMap);
                }
            },
            parameterOptions() {
                return this.$store.state.siteData.parameterOptions;
            }
        }
    }
</script>
