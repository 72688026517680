<template>
    <div style="width: fit-content; background-color: white;">
        <div>
            <b-sidebar id="sidebar-help" title="Help" shadow right>
                <div style="padding: 1rem;">
                    <h2 class="pb-5" style="font-weight: 600;">Trade Maps</h2>
                    <h3 class="pt-5">Tools:</h3>
                    <h4><b>Parameter to map: </b>This selects which metric of the the calculator statistics to map.</h4>
                    <hr>
                    <h4>Need support? Visit the Support page or <a href="/support">click here</a></h4>
                </div>
            </b-sidebar>
        </div>
    
        <table v-if="namespace !== 'undefined'" style="text-align: left; margin: 2px 0 1px 0;"> 
            <tr>
                <td style="vertical-align: top;">
                    <table>
                        <tr>
                             <td style="vertical-align: bottom">
            <b-form-group label="" v-slot="{ ariaDescribedby }" style="padding: 4px; margin: 0px 15px 0px -10px; font-weight: bold; 
                        border: 1px solid gray; border-radius: 3px; background-color: #e7edf5; width: min-content">
                <b-form-radio-group
                    id="radio-group-1"
                    v-model="buySell"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="radio-options"
                    size="sm"></b-form-radio-group>
            </b-form-group>
                </td>
                            <td style="vertical-align: top">  
                        <commodity-box v-bind:namespace="namespace"
                                       v-bind:max-legs="2"
                                       v-bind:showMultSelector="false">
                            <template v-slot:contract-aligner>
                                <contract-aligner-nearest-open-selector v-bind:namespace="namespace"
                                                           v-bind:title="'Spread Aligner'"></contract-aligner-nearest-open-selector>     
                            </template>
                        </commodity-box>
                </td>
                </tr>
        </table>
        </td>
        <td style="vertical-align: bottom; text-align: right; height: available; padding-left: 3px; background-color: white;">

            <table style="color: black; height: 100%; background-color: white;">
                <tr>
                    <td style="vertical-align: bottom; background-color: white">
                <b-row align-h="start" align-v="end" class="row no-gutters" style=" background-color: white; padding-bottom: 0px">
                    <b-col md="auto"> 
                        <b-button-group style="border: 1px solid gray; white-space: nowrap; background-color: #E8E8E8;
                         font-weight: bold; margin: 0 2px 0 1px; padding: 3px 3px 2px 2px; border-radius: 3px"> 

                            <span v-if="$store.state.user.role === 'superadmin'">
                                <span style="padding-top: 9px; font-weight: bold; color: black">firebase storage dir:</span>
                                    <b-form-select style="border:1px solid gray; width: auto; margin: 0 6px 0 2px"
                                         v-model="firebaseStorageDirectory" :options="firebaseStorageDirectoryOptions"></b-form-select>
                            </span>

                            <parameter-to-map v-bind:namespace="namespace"></parameter-to-map>
                            <b-button v-b-toggle.sidebar-help variant="warning" style="border: 1px solid darkgray; margin: 0 0 2px 8px;">Help</b-button>
                        </b-button-group>
                    </b-col>
                </b-row>
        </td>
                <td style="vertical-align: top; text-align: left; height: available; background-color: white;">
                        <div style="border: 1px solid gray; margin-left: 2px; padding: 2px; border-radius: 3px">
                        <div style="white-space: nowrap"><b>mpe</b> = maximum profitable excursion</div>
                        <div style="white-space: nowrap"><b>mae</b> = maximum adverse excursion</div>
                        <div style="white-space: nowrap"><b>appd</b> = average profit per day</div>
                    </div>
                </td>
        </tr>
        </table>

        </td>
        </tr>
        </table>                   
    </div>
</template>

<script>

    import commodityBox from '@/components/commodity-box';
    import contractAlignerNearestOpenSelector from '@/components/contract-aligner-nearest-open-selector';
    import parameterToMap from '@/components/parameter-to-map';

    export default {
        components: {
            commodityBox, contractAlignerNearestOpenSelector, parameterToMap
        },
        mounted() {
          //  console.log("trade-maps-input.vue mounted() starting. this.namespace=", this.namespace);
        },
        props: ['namespace'],
        data: function(){
            return {
                firebaseStorageDirectoryOptions: [
                    {value: "maps", text: "maps"},
                    {value: "maps2", text: "maps2"},
                ],
                options: [
                    {text: 'buy', value: 'buy'},
                    {text: 'sell', value: 'sell'}
                ]
            };
        },
        computed: {
            firebaseStorageDirectory: {
                get(){
                    return this.$store.state.siteData.firebaseStorageDirectory;
                },
                set(firebaseStorageDirectory){
                    this.$store.commit('siteData/setFirebaseStorageDirectory', firebaseStorageDirectory);
                }
            },
            buySell: {
                get() {
                    return this.$store.state[this.namespace].buySell;
                },
                set(buySell) {
                    this.$store.commit(this.namespace + '/setBuySell', buySell);
                }
            }
        }
    };

</script>
